import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { H1 } from "../components/text/H1"
import { Paragraph } from "../components/text/Paragraph"
import NewsletterIllustration from "../images/newsletter_illustration.svg"

export interface NewsletterBestaetigungProps {
  location: any
}

const NewsletterBestaetigung: React.SFC<NewsletterBestaetigungProps> = ({
  location,
}) => {
  enum StatusType {
    notSet,
    success,
    error,
  }

  const [status, setStatus] = useState<StatusType>(StatusType.notSet)

  // need default url otherwise cannot build
  const url = new URL(
    location.href
      ? location.href
      : "https://diepsychotherapieapp.de/newsletter-bestaetigung?hash=abcd&id=123"
  )
  const hash = url.searchParams.get("hash")
  const id = url.searchParams.get("id")

  const checkForHash = async () => {
    const response = await fetch("/.netlify/functions/checkHash", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hash, id }),
    })
    if (response.status === 200) {
      setStatus(StatusType.success)
    } else {
      setStatus(StatusType.error)
    }
  }

  useEffect(() => {
    console.log("run")

    checkForHash()
  }, [])

  return (
    <Layout>
      <Section>
        <div className="text-center my-10 lg:my-24">
          <div className="flex justify-center mb-20">
            <img
              src={NewsletterIllustration}
              className="w-4/12"
              alt="Newsletter Bestätigung Illustration"
            />
          </div>
          {status !== StatusType.notSet && (
            <div>
              <H1
                text={
                  status === StatusType.success
                    ? "Bestätigung war erfolgreich"
                    : "Etwas lief schief"
                }
              />
              <Paragraph>
                {status === StatusType.success
                  ? "Vielen Dank für Ihre Unterstützung!"
                  : "Kopieren Sie den Link aus der Mail vollständig und geben Sie ihn erneut ein. Falls dies nicht funktioniert, schreiben Sie gerne eine kurze Mail (jonas@diepsychotherapieapp.de)."}
              </Paragraph>
            </div>
          )}
          {status === StatusType.notSet && (
            <div className="flex justify-center ">
              <svg
                className="animate-spin -ml-1 mr-3 h-16 w-16 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </Section>
    </Layout>
  )
}

export default NewsletterBestaetigung
